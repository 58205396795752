import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import type { HeadFC } from "gatsby";
import type { QueryReturnHead } from "../common/interfaces/types";

import config from "../common/config";

import "../styles/pages/Auth.scss";

import Logo from "../assets/images/icons/logo-big.svg";

import { SignupForm } from "../containers";

import { Footer, Header, SEO } from "../components";

const Auth = () => {
  const { t } = useTranslation("contact");

  return (
    <div style={{height: "70.5vh" }}>
      <Header />
        <div className="auth">
        <a href="https://ascendingbull.com" className="auth__logo">
            <img src={Logo} alt={config.brand} className="auth__logo" />
        </a>
        <div className="auth__forms">
            <SignupForm />
            </div>
          </div>
        <Footer />
          </div>
        
  );
}

export default Auth;

export const Head: HeadFC<QueryReturnHead> = ({ data: { locales } }) => {
  const about = locales.edges.find(({ node }) => (node.ns === "contact"));

  const { "title-signup": titleSeo } = about ? JSON.parse(about.node.data) : {
    "title-signup": ""
  };

  return (
    <SEO title={titleSeo} />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;